import React from 'react'
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2
} from 'react-html-parser'
import { MDBCollapse } from 'mdbreact'
import $ from 'jquery'
import 'bootstrap'
import 'react-perfect-scrollbar/dist/css/styles.css'
import PerfectScrollbar from 'react-perfect-scrollbar'
import Loader from 'react-loader-spinner'
import utility from '../../Utility/utility'
import { withRouter } from 'react-router-dom'
import AddToCalendar from 'react-add-to-calendar'
import 'react-add-to-calendar/dist/react-add-to-calendar.css'
import { Constants } from '../../Constants'
import { Scrollbars } from 'react-custom-scrollbars'
import { isMobile } from 'react-device-detect'
import { Trans } from 'react-i18next'
import session from './session'
import * as images from 'round-flags'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import './styles.css'
import getUserCountry from 'js-user-country'
export class Content extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      agenda: this.props.agenda,
      collapseID: '',
      descriptionCollapseId: '',
      showStatusCheckLoader:
        props.alreadyLoadedSessionIndex > -1 ||
        props.alreadyLoadedSessionIndex > -1,
      selectedSessionIndex: props.alreadyLoadedSessionIndex,
      selectedSubSessionIndex: props.alreadyLoadedSubSessionIndex,
      selectedSpeaker: {},
      animatorStoper: false,
      showAgendaTabsScrollButtons: false,
      currentScreenWidth: window.innerWidth,
      agendaTitle: ''
    }
    this.speakerCollapseIndexArr = []
    this.descriptionCollapseIndexArr = []
    this.toggleClicked = false
    this.minNoOfSpeakersToShow = 3
    this.currentItemOffSet = 0
    this.selectedAgendadate = ''
    this.selectedIndexAgenda = []
  }
  OnNavigateToPage = (path, data = null) => {
    window.scrollTo(0, 0)
    if (path.indexOf('home') > -1) {
      path = '/'
    } else if (path != '/') {
      path = '/' + path
    }
    console.log(path)
    console.log(this.props.history)
    if (data == null) {
      this.props.history.push(path)
    } else {
      this.props.history.push({
        pathname: path,
        state: data
      })
    }
  }
  componentWillReceiveProps (nextProps) {
    if (nextProps.loadSessionData == true) {
      this.OnClickVirtualAccessButton(
        nextProps.streamType,
        nextProps.agendaIndex,
        nextProps.sessionIndex,
        nextProps.subSessionIndex
      )
      nextProps.unsetSessionData()
    }
  }
  componentDidMount () {
    console.log(this.props)
    var link = localStorage.getItem('AgendaNavigation')
    // if(link != null){
    //     this.OnNavigateToPage(this.props.event.alias+'/agenda'+link)
    //     // console.log(newLink)
    // // window.history.pushState({}, null, newLink);
    // // localStorage.removeItem('AgendaNavigation')
    // }

    if (this.props.event.is_open_stream == 1) {
      this.OnClickVirtualAccessButton(1, 0, 0, -1)
    }
    this.setState({ currentScreenWidth: window.innerWidth })
    window.addEventListener('resize', event => {
      this.setState({ currentScreenWidth: window.innerWidth })
    })
    document.getElementsByTagName('body')[0].scrollTop = 0
    this.checkWatchButtonLiveStatus()
    if (this.props.userSelectedTimeZone) {
      this.timeZoneConvertor()
    } else {
      // this.OnChangeTimeZone(this.props.defaultTimeZone)
    }
    this.setUpMobileListeners()
    let totalElementsWidth = 0
    let agendaUl = document.getElementById('agenda-tabs')
    let agendaUlWidth = agendaUl.offsetWidth
    for (var i = 0; i < agendaUl.children.length; i++) {
      let li = agendaUl.children[i]
      totalElementsWidth += li.offsetWidth
      if (i == this.props.defaultAgendaIndex) {
        let offset = li.offsetLeft
        // + li.offsetWidth
        this.currentItemOffSet = li.offsetLeft
        if (offset > agendaUlWidth) {
          this.setNextPrevJquery(true, offset)
          break
        }
      }
    }
    if (totalElementsWidth > agendaUlWidth) {
      this.setState({ showAgendaTabsScrollButtons: true }, () => {
        this.setNextPrevJquery()
      })
    }
  }

  timeformatConvertor = date => {
    var hours = date.getHours()
    var minutes = date.getMinutes()
    var ampm = hours >= 12 ? 'PM' : 'AM'
    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes
    var strTime = hours + ':' + minutes + ' ' + ampm
    return strTime
  }
  toggleCollapse = collapseID => () => {
    if (this.toggleClicked) {
      return
    }
    this.toggleClicked = true
    setTimeout(() => {
      this.toggleClicked = false
    }, 400)
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ''
    }))
    var index = collapseID.slice(-2)
    if (this.speakerCollapseIndexArr.indexOf(index) < 0) {
      this.speakerCollapseIndexArr.push(index)
    }
    for (var i = 0; i < this.speakerCollapseIndexArr.length; i++) {
      var itemIndex = this.speakerCollapseIndexArr[i]
      if (itemIndex == index) {
        if (
          document
            .getElementById('icon' + itemIndex)
            .classList.contains('fa-plus-square')
        ) {
          document
            .getElementById('icon' + itemIndex)
            .classList.remove('fa-plus-square')
          document
            .getElementById('icon' + itemIndex)
            .classList.add('fa-minus-square')
        } else {
          document
            .getElementById('icon' + itemIndex)
            .classList.remove('fa-minus-square')
          document
            .getElementById('icon' + itemIndex)
            .classList.add('fa-plus-square')
        }
      } else {
        if (
          !document
            .getElementById('icon' + itemIndex)
            .classList.contains('fa-plus-square')
        ) {
          document
            .getElementById('icon' + itemIndex)
            .classList.remove('fa-minus-square')
          document
            .getElementById('icon' + itemIndex)
            .classList.add('fa-plus-square')
        }
      }
    }
  }
  toggleDescriptionCollapse = collapseID => () => {
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ''
    }))
    var index = collapseID.slice(-2)
    if (this.descriptionCollapseIndexArr.indexOf(index) < 0) {
      this.descriptionCollapseIndexArr.push(index)
    }
    for (var i = 0; i < this.descriptionCollapseIndexArr.length; i++) {
      var itemIndex = this.descriptionCollapseIndexArr[i]
      if (itemIndex == index) {
        if (
          document
            .getElementById('collapsibleNavbar' + itemIndex)
            .classList.contains('fa-plus-square')
        ) {
          document
            .getElementById('collapsibleNavbar' + itemIndex)
            .classList.remove('fa-plus-square')
          document
            .getElementById('collapsibleNavbar' + itemIndex)
            .classList.add('fa-minus-square')
        } else {
          document
            .getElementById('collapsibleNavbar' + itemIndex)
            .classList.remove('fa-minus-square')
          document
            .getElementById('collapsibleNavbar' + itemIndex)
            .classList.add('fa-plus-square')
        }
      } else {
        if (
          !document
            .getElementById('collapsibleNavbar' + itemIndex)
            .classList.contains('fa-plus-square')
        ) {
          document
            .getElementById('collapsibleNavbar' + itemIndex)
            .classList.remove('fa-minus-square')
          document
            .getElementById('collapsibleNavbar' + itemIndex)
            .classList.add('fa-plus-square')
        }
      }
    }
  }
  OnClickVirtualAccessButton = (
    type,
    agendaIndex,
    sessionIndex,
    subSessionIndex = -1
  ) => {
    console.log(type, agendaIndex, sessionIndex, subSessionIndex)
    if (this.state.showStatusCheckLoader) {
      return
    }
    let agendaItem = this.state.agenda[agendaIndex]
    var sessionItem = null
    /*** parallel session separate time */
    if (subSessionIndex > -1) {
      sessionItem =
        agendaItem.sessions.data[sessionIndex].subSessions[subSessionIndex]
    } else {
      sessionItem = agendaItem.sessions.data[sessionIndex]
    }
    if (sessionItem.isPurchased == '0') {
      this.props.onClickVirtualAccessButton(
        type,
        agendaIndex,
        sessionIndex,
        subSessionIndex,
        false
      )
      return
    }
    if (
      sessionItem.originalStartTime == null ||
      sessionItem.originalEndTime == null
    ) {
      sessionItem = agendaItem.sessions.data[sessionIndex]
    }
    /********* */
    // sessionItem = agendaItem.sessions.data[sessionIndex]
    this.currentTimeInEventTimeZone = utility.calculateZoneSpecificTime(
      '',
      this.timeZone
    )
    this.currentTimeInMinutes =
      this.currentTimeInEventTimeZone.getHours() * 60 +
      this.currentTimeInEventTimeZone.getMinutes()
    if (
      this.currentTimeInEventTimeZone.setHours(0, 0, 0, 0) <
      agendaItem.dateMilliseconds
    ) {
      this.props.onShowJoinSessionError(
        'Session Not Started',
        'This Live Session has not yet started, please come back later at the scheduled time'
      )
      return
    } else if (
      this.currentTimeInEventTimeZone.setHours(0, 0, 0, 0) ==
      agendaItem.dateMilliseconds
    ) {
      if (
        this.currentTimeInMinutes > sessionItem.sessionEndTimeMinutes &&
        sessionItem.virtual_access_status != 2
      ) {
        this.props.onShowJoinSessionError(
          'Session Ended',
          'Live Session Stream has ended. The recorded video of the session will be uploaded and available soon'
        )
        return
      }
      if (this.currentTimeInMinutes < sessionItem.sessionStartTimeMinutes) {
        this.props.onShowJoinSessionError(
          'Session Not Started',
          'This Live Session has not yet started, please come back later at the scheduled time'
        )
        return
      }
    } else {
      let link
      if (subSessionIndex > -1) {
        link =
          agendaItem.sessions.data[sessionIndex].subSessions[subSessionIndex]
            .virtual_access_link
      } else {
        link = sessionItem.virtual_access_link
      }
      if (!utility.validateUrl(link)) {
        this.props.onShowJoinSessionError(
          'Recording Not Available',
          'The recorded video of the session will be uploaded and available soon'
        )
        return
      }
    }
    this.setState({
      selectedSessionIndex: sessionIndex,
      selectedSubSessionIndex: subSessionIndex
    })
    this.setState({ showStatusCheckLoader: true })
    this.props.onClickVirtualAccessButton(
      type,
      agendaIndex,
      sessionIndex,
      subSessionIndex
    )
  }
  OnUserStatusLoaded = () => {
    this.setState({ showStatusCheckLoader: false })
  }

  checkWatchButtonLiveStatus = () => {
    var agenda = this.state.agenda
    let timeZoneSplit = this.props.defaultTimeZone.split(':')
    let timeZoneHour = parseInt(timeZoneSplit[0])
    this.timeZone = parseFloat(timeZoneHour + '.' + timeZoneSplit[1])
    this.currentTimeInEventTimeZone = !this.props.showingLocalTime
      ? utility.calculateZoneSpecificTime('', this.timeZone)
      : new Date()
    this.currentTimeInMinutes =
      this.currentTimeInEventTimeZone.getHours() * 60 +
      this.currentTimeInEventTimeZone.getMinutes()
    for (let i = 0; i < agenda.length; i++) {
      let dateSplit = agenda[i].originalDate.split('-')
      let month = parseInt(dateSplit[1])
      if (month < 10) {
        month = '0' + month
      }
      let day = parseInt(dateSplit[0])
      if (day < 10) {
        day = '0' + day
      }
      let dateString = dateSplit[2] + '/' + month + '/' + day
      let date = new Date(dateString).setHours(0, 0, 0, 0)
      agenda[i].dateMilliseconds = date
      for (let j = 0; j < agenda[i].sessions.data.length; j++) {
        let sessionItem = agenda[i].sessions.data[j]
        var parentSessionItem = null
        if (!sessionItem.originalStartTime) {
          sessionItem.originalStartTime = sessionItem.start_time
        }
        if (!sessionItem.originalEndTime) {
          sessionItem.originalEndTime = sessionItem.end_time
        }
        sessionItem.isHappeningNow = false
        if (
          sessionItem.originalStartTime == null ||
          sessionItem.originalEndTime == null
        ) {
          sessionItem.virtualAccessStatus = 0
          continue
        }
        let sessionStartTimeSplit = sessionItem.originalStartTime.split(':')
        let sessionEndTimeSplit = sessionItem.originalEndTime.split(':')
        let sessionStartTimeMinutes =
          parseInt(sessionStartTimeSplit[0]) * 60 +
          parseInt(sessionStartTimeSplit[1])
        let sessionEndTimeMinutes =
          parseInt(sessionEndTimeSplit[0]) * 60 +
          parseInt(sessionEndTimeSplit[1])
        sessionItem.sessionStartTimeMinutes = sessionStartTimeMinutes
        sessionItem.sessionEndTimeMinutes = sessionEndTimeMinutes
        let subSessions = []
        if (sessionItem.hasMultiSession == 1) {
          parentSessionItem = sessionItem
          subSessions = sessionItem.subSessions
        } else {
          subSessions = [sessionItem]
        }
        for (var k = 0; k < subSessions.length; k++) {
          sessionItem = subSessions[k]
          sessionItem.isHappeningNow = false
          /* handling parallel stream session time here */
          if (
            sessionItem.originalStartTime != null &&
            sessionItem.originalEndTime != null
          ) {
            sessionStartTimeSplit = sessionItem.originalStartTime.split(':')
            sessionEndTimeSplit = sessionItem.originalEndTime.split(':')
            sessionStartTimeMinutes =
              parseInt(sessionStartTimeSplit[0]) * 60 +
              parseInt(sessionStartTimeSplit[1])
            sessionEndTimeMinutes =
              parseInt(sessionEndTimeSplit[0]) * 60 +
              parseInt(sessionEndTimeSplit[1])
            sessionItem.sessionStartTimeMinutes = sessionStartTimeMinutes
            sessionItem.sessionEndTimeMinutes = sessionEndTimeMinutes
          }
          //***************** */
          let virtualAccessStatus = sessionItem.virtual_access_status
          // if(sessionItem.virtual_access_status != 0){
          if (date == this.currentTimeInEventTimeZone.setHours(0, 0, 0, 0)) {
            if (
              this.currentTimeInMinutes >= sessionStartTimeMinutes &&
              this.currentTimeInMinutes < sessionEndTimeMinutes
            ) {
              sessionItem.virtual_access_status = 1
              sessionItem.isHappeningNow = true
            }
            if (this.currentTimeInMinutes < sessionEndTimeMinutes) {
              sessionItem.virtual_access_status = 1
            } else {
              sessionItem.virtual_access_status = 2
              if (parentSessionItem) {
                parentSessionItem.virtual_access_status = 2
              }
              sessionItem.isOver = true
            }
          } else if (
            this.currentTimeInEventTimeZone.setHours(0, 0, 0, 0) < date
          ) {
            sessionItem.virtual_access_status = 1
          } else {
            sessionItem.virtual_access_status = 2
            if (parentSessionItem) {
              parentSessionItem.virtual_access_status = 2
            }
            sessionItem.isOver = true
          }
          if (sessionItem.isBreak == '1') {
            sessionItem.speakers.data = []
          }
          // }
        }
      }
    }
    this.setState({ agenda }, () => {
      for (let i = 0; i < this.state.agenda.length; i++) {
        for (let j = 0; j < this.state.agenda[i].sessions.data.length; j++) {
          if (this.state.agenda[i].sessions.data[j].hasMultiSession == 1) {
            if (
              this.state.agenda[i].sessions.data[j].subSessions[0]
                .isHappeningNow
            ) {
              let elem = document.getElementById(i + '' + j)
              window.scrollTo({
                top: elem.offsetTop,
                behavior: 'smooth'
              })
            }
          } else {
            if (this.state.agenda[i].sessions.data[j].isHappeningNow) {
              let elem = document.getElementById(i + '' + j)
              window.scrollTo({
                top: elem.offsetTop,
                behavior: 'smooth'
              })
            }
          }
        }
      }
    })
  }
  OnClickFavorite = (agendaIndex, sessionIndex, subSessionIndex = -1) => {
    let agenda = this.state.agenda
    var sessionItem = null
    var sessionId = 0
    var subSessionId = 0
    sessionItem = agenda[agendaIndex].sessions.data[sessionIndex]
    sessionId = sessionItem.id
    if (subSessionIndex > -1) {
      sessionItem =
        agenda[agendaIndex].sessions.data[sessionIndex].subSessions[
          subSessionIndex
        ]
      subSessionId = sessionItem.id
    }
    if (sessionItem.is_favourite == 1) {
      sessionItem.is_favourite = 0
    } else {
      sessionItem.is_favourite = 1
    }
    var data = {
      agenda_id: agenda[agendaIndex].id,
      session_id: sessionId,
      sub_session_id: subSessionId,
      is_favourite: sessionItem.is_favourite
    }
    this.props.onMarkFavorite(data)
    this.setState({ agenda })
  }
  OnClickSeeBio = speaker => {
    if (speaker.description == null) {
      return
    }
    this.setState({ selectedSpeaker: speaker }, () => {
      $('#bioModalSchedule').modal('show')
    })
  }
  OnChangeTimeZone = e => {
    this.props.timeZoneChanged(e)
  }
  timeZoneConvertor = () => {
    let date = new Date().toString()
    let gmt = date.split(' ')[5].slice(3, this.length)
    let start = gmt.slice(0, 3)
    let end = gmt.slice(3, gmt.length)
    let zoneOffset = start + ':' + end

    this.OnChangeTimeZone(zoneOffset)
  }
  renderWatchButton = props => {
    if (props.virtualAccessStatus == 0) {
      return null
    }
    return (
      <div class={`row justify-content-end`}>
        <div class='col-10 pl-md-0'>
          <a
            style={{ cursor: 'pointer' }}
            onClick={() =>
              this.OnClickVirtualAccessButton(
                props.virtualAccessStatus,
                props.agendaIndex,
                props.sessionIndex
              )
            }
            class={`btn session_btn`}
          >
            {props.virtualAccessStatus == 1 ? (
              props.isHappeningNow ? (
                <i class='fas fa-circle mr-3 tab-live' />
              ) : (
                <i class='fas fa-broadcast-tower mr-2' />
              )
            ) : (
              <i class='fas fa-play mr-2' />
            )}
            {props.virtualAccessStatus == 1
              ? this.props.translation.watchLive
              : this.props.translation.watchRecorded}
          </a>
          {this.state.showStatusCheckLoader &&
          this.state.selectedSessionIndex == props.sessionIndex &&
          this.props.defaultAgendaIndex == props.agendaIndex ? (
            <div
              style={{
                position: 'absolute',
                top: 4,
                left: 175,
                marginLeft: '12px'
              }}
              className='loaderagenda'
            >
              <Loader type='Oval' color='#00b6ff' height={20} width={20} />
            </div>
          ) : null}
        </div>
      </div>
    )
  }
  OnClickAgendaTab = index => {
    this.props.onClickAgendaTab(index)
    let agendaUl = document.getElementById('agenda-tabs')
    let item = agendaUl.children[index]
    if (item.offsetLeft + item.offsetWidth > agendaUl.offsetWidth) {
      this.setNextPrevJquery(true)
    } else if (
      this.currentItemOffSet - agendaUl.children[index].offsetLeft >
      0
    ) {
      this.setNextPrevJquery(true, -1)
    }
    this.currentItemOffSet = agendaUl.children[index].offsetLeft
  }
  setNextPrevJquery (autoScroll = false, scrollVal = 0) {
    $(document).ready(function () {
      var instance = $('.hs__wrapper')
      $.each(instance, function (key, value) {
        var arrows = $(instance[key]).find('.arrow'),
          prevArrow = arrows.filter('.arrow-prev'),
          nextArrow = arrows.filter('.arrow-next'),
          box = $(instance[key]).find('.aganda_tab'),
          x = 0,
          mx = 0,
          maxScrollWidth =
            box[0].scrollWidth - box[0].clientWidth - box.width() / 2
        if (autoScroll) {
          x = box.width() + box.scrollLeft()
          if (scrollVal > 0) {
            x = scrollVal
          } else if (scrollVal < 0) {
            x = -x
          }
          box.animate({
            scrollLeft: x
          })
        } else {
          $(arrows).on('click', function () {
            if ($(this).hasClass('arrow-next')) {
              x = box.width() / 2 + box.scrollLeft() - 10
              box.animate({
                scrollLeft: x
              })
            } else {
              x = box.width() / 2 - box.scrollLeft() - 10
              box.animate({
                scrollLeft: -x
              })
            }
          })

          $(box).on({
            mousemove: function (e) {
              var mx2 = e.pageX - this.offsetLeft
              if (mx) this.scrollLeft = this.sx + mx - mx2
            },
            mousedown: function (e) {
              this.sx = this.scrollLeft
              mx = e.pageX - this.offsetLeft
            },
            scroll: function () {
              toggleArrows()
            }
          })

          $(document).on('mouseup', function () {
            mx = 0
          })
        }

        function toggleArrows () {
          if (box.scrollLeft() > maxScrollWidth - 10) {
            // disable next button when right end has reached
            nextArrow.addClass('disabled')
          } else if (box.scrollLeft() < 10) {
            // disable prev button when left end has reached
            prevArrow.addClass('disabled')
          } else {
            // both are enabled
            nextArrow.removeClass('disabled')
            prevArrow.removeClass('disabled')
          }
        }
      })
    })
  }
  setUpMobileListeners () {
    $('.select-box__current').click(function () {
      $(this).toggleClass('opn-sbox')
      $('.select-box__list').toggleClass('toggle-box_list')
    })
    $('input[name="day-select"]').click(function () {
      $(this).tab('show')
      $(this).removeClass('active')
    })
  }
  renderAgendaTabs = props => {
    var agenda = props.agenda
    var selectedDateString = ''
    return (
      <>
        <div class='agenda-tab-row d-none d-lg-block position-relative'>
          {this.state.showAgendaTabsScrollButtons ? (
            <a class='arrow disabled arrow-prev float-left'>
              <i class='fas fa-angle-left'></i>
            </a>
          ) : null}
          <ul id='agenda-tabs' class='nav nav-tabs aganda_tab' role='tablist'>
            {agenda
              ? agenda.map((agend, index) => {
                  var h_id = '#agenda' + index
                  var activeClass =
                    index == this.props.defaultAgendaIndex
                      ? 'nav-link active px-3 rounded-0'
                      : 'nav-link px-3 rounded-0'

                  if (agend.date) {
                    var dateString = ''
                    if (!agend.firstDate) {
                      var newdate = agend.date.split('-').reverse().join('/')
                      dateString = new Date(newdate).toDateString()
                    } else {
                      let firstDate = agend.firstDate
                        .split('-')
                        .reverse()
                        .join('/')
                      let firstDateObj = new Date(firstDate)
                      let firstDateSplited = firstDateObj
                        .toDateString()
                        .split(' ')

                      let secondDate = agend.date.split('-').reverse().join('/')
                      let secondDateObj = new Date(secondDate)
                      let secondDateSplited = secondDateObj
                        .toDateString()
                        .split(' ')

                      if (firstDateObj.getDate() != secondDateObj.getDate()) {
                        dateString =
                          firstDateSplited[2] +
                          '-' +
                          secondDateSplited[2] +
                          ' ' +
                          firstDateSplited[1] +
                          ' ' +
                          firstDateSplited[3]
                      } else if (
                        firstDateObj.getMonth() != secondDateObj.getMonth()
                      ) {
                        dateString =
                          firstDateSplited[1] +
                          ' ' +
                          firstDateSplited[2] +
                          '-' +
                          secondDateSplited[1] +
                          ' ' +
                          secondDateSplited[2] +
                          ' ' +
                          firstDateSplited[3]
                      } else {
                        dateString =
                          firstDateSplited[1] +
                          ' ' +
                          firstDateSplited[2] +
                          ' ' +
                          firstDateSplited[3] +
                          '-' +
                          secondDateSplited[1] +
                          ' ' +
                          secondDateSplited[2] +
                          ' ' +
                          secondDateSplited[3]
                      }
                    }
                  }
                  return (
                    <li
                      style={{
                        pointerEvents: this.state.showStatusCheckLoader
                          ? 'none'
                          : 'auto'
                      }}
                      onClick={() => this.OnClickAgendaTab(index)}
                      class='nav-item slide'
                      key={index}
                    >
                      <a class={activeClass} data-toggle='tab' href={h_id}>
                        <h6 class='text-center font-weight-bold'>
                          {agend.title}
                        </h6>
                        <p
                          style={{ fontWeight: 500 }}
                          class='text-center text-capitalize mb-0'
                        >
                          {dateString}
                        </p>
                      </a>
                    </li>
                  )
                })
              : null}
          </ul>
          {this.state.showAgendaTabsScrollButtons ? (
            <a class='arrow arrow-next float-right'>
              <i class='fas fa-angle-right'></i>
            </a>
          ) : null}
        </div>
        <div class='select-box mb-3 d-lg-none'>
          <div class='select-box__current' tabindex='0'>
            {agenda
              ? agenda.map((agend, index) => {
                  if (agend.date) {
                    var dateString = ''
                    if (!agend.firstDate) {
                      var newdate = agend.date.split('-').reverse().join('/')
                      dateString = new Date(newdate).toDateString()
                      if (!this.selectedIndexAgenda.includes(dateString)) {
                        this.selectedIndexAgenda.push(dateString)
                      }
                    } else {
                      let firstDate = agend.firstDate
                        .split('-')
                        .reverse()
                        .join('/')
                      let firstDateObj = new Date(firstDate)
                      let firstDateSplited = firstDateObj
                        .toDateString()
                        .split(' ')

                      let secondDate = agend.date.split('-').reverse().join('/')
                      let secondDateObj = new Date(secondDate)
                      let secondDateSplited = secondDateObj
                        .toDateString()
                        .split(' ')

                      if (firstDateObj.getDate() != secondDateObj.getDate()) {
                        dateString =
                          firstDateSplited[2] +
                          '-' +
                          secondDateSplited[2] +
                          ' ' +
                          firstDateSplited[1] +
                          ' ' +
                          firstDateSplited[3]
                      } else if (
                        firstDateObj.getMonth() != secondDateObj.getMonth()
                      ) {
                        dateString =
                          firstDateSplited[1] +
                          ' ' +
                          firstDateSplited[2] +
                          '-' +
                          secondDateSplited[1] +
                          ' ' +
                          secondDateSplited[2] +
                          ' ' +
                          firstDateSplited[3]
                      } else {
                        dateString =
                          firstDateSplited[1] +
                          ' ' +
                          firstDateSplited[2] +
                          ' ' +
                          firstDateSplited[3] +
                          '-' +
                          secondDateSplited[1] +
                          ' ' +
                          secondDateSplited[2] +
                          ' ' +
                          secondDateSplited[3]
                      }
                      if (!this.selectedIndexAgenda.includes(dateString)) {
                        this.selectedIndexAgenda.push(dateString)
                      }
                    }
                  }
                  return (
                    <div class='select-box__value'>
                      <input
                        class='select-box__input'
                        type='radio'
                        id={index}
                        name='day-select'
                        checked='checke'
                        onClick={() => this.OnClickAgendaTab(index)}
                        key={index}
                      />
                      <p class='select-box__input-text'>
                        {agenda[this.props.defaultAgendaIndex].title +
                          ' - ' +
                          this.selectedIndexAgenda[
                            this.props.defaultAgendaIndex
                          ]}
                      </p>
                    </div>
                  )
                })
              : null}
            <img
              class='select-box__icon'
              src='/images/agenda-drop.svg'
              alt='Arrow Icon'
              aria-hidden='true'
            />
          </div>
          <ul class='select-box__list bg-white border pt-2 px-2 mb-0'>
            {agenda.map((agend, index) => {
              if (agend.date) {
                var dateString = ''
                if (!agend.firstDate) {
                  var newdate = agend.date.split('-').reverse().join('/')
                  dateString = new Date(newdate).toDateString()
                } else {
                  let firstDate = agend.firstDate.split('-').reverse().join('/')
                  let firstDateObj = new Date(firstDate)
                  let firstDateSplited = firstDateObj.toDateString().split(' ')

                  let secondDate = agend.date.split('-').reverse().join('/')
                  let secondDateObj = new Date(secondDate)
                  let secondDateSplited = secondDateObj
                    .toDateString()
                    .split(' ')

                  if (firstDateObj.getDate() != secondDateObj.getDate()) {
                    dateString =
                      firstDateSplited[2] +
                      '-' +
                      secondDateSplited[2] +
                      ' ' +
                      firstDateSplited[1] +
                      ' ' +
                      firstDateSplited[3]
                  } else if (
                    firstDateObj.getMonth() != secondDateObj.getMonth()
                  ) {
                    dateString =
                      firstDateSplited[1] +
                      ' ' +
                      firstDateSplited[2] +
                      '-' +
                      secondDateSplited[1] +
                      ' ' +
                      secondDateSplited[2] +
                      ' ' +
                      firstDateSplited[3]
                  } else {
                    dateString =
                      firstDateSplited[1] +
                      ' ' +
                      firstDateSplited[2] +
                      ' ' +
                      firstDateSplited[3] +
                      '-' +
                      secondDateSplited[1] +
                      ' ' +
                      secondDateSplited[2] +
                      ' ' +
                      secondDateSplited[3]
                  }
                }
              }
              return (
                <li>
                  <label class='select-box__option' for={index}>
                    {agend.title} - {dateString}
                  </label>
                </li>
              )
            })}
          </ul>
        </div>
      </>
    )
  }
  render () {
    const timeTooltip = props => (
      <Tooltip id='button-tooltip' {...props}>
        Switch Time
      </Tooltip>
    )
    const agenda = this.state.agenda
    const selectedSpeaker = this.state.selectedSpeaker
    let icon = { 'calendar-plus-o': 'left' }
    let items = [
      { google: 'Google' },
      { outlook: 'Outlook' },
      { apple: 'Apple Calendar' }
    ]
    return (
      <>
        <section class='agenda_time py-3' id='ajenda'>
          <div class='container'>
            <div class='row pt-2'>
              <div class='col-sm-8 order-last order-lg-first'>
                <div class='time-zone-area'>
                  <div
                    class={`time-zone-input ${
                      this.props.userSelectedTimeZone ? ' time-upadate' : ''
                    }`}
                  >
                    <div class='time-zone-content'>
                      <span
                        class={`${
                          this.state.animatorStoper == false
                            ? 'localtime'
                            : 'event-time'
                        }`}
                        style={{ width: 'max-content' }}
                        id='viewer1'
                      >
                        <img
                          //src="https://hatscripts.github.io/circle-flags/flags/gb.svg"
                          src={images[this.props.countryCode.toUpperCase()]}
                          width='20'
                        />
                        <b>
                          {' '}
                          <Trans>event_zone</Trans>
                        </b>{' '}
                        {this.timeformatConvertor(
                          utility.calculateZoneSpecificTime('', this.timeZone)
                        )}{' '}
                        {`(GMT ${this.props.timeZone.slice(0, 3)} )`}
                      </span>
                      <span>
                        <img
                          // src="https://hatscripts.github.io/circle-flags/flags/es.svg" width="20"/> */}
                          src={images[Constants.countryFlag?.toUpperCase()]}
                        />
                        <b>
                          {' '}
                          <Trans>my_timezone</Trans>
                        </b>{' '}
                        {this.timeformatConvertor(new Date())}{' '}
                        {`(GMT ${new Date()
                          .toString()
                          .split(' ')[5]
                          .slice(3, 6)} )`}
                      </span>
                    </div>
                    {this.state.currentScreenWidth > 767 ? (
                      <OverlayTrigger
                        placement='right'
                        overlay={timeTooltip}
                        arrowProps={<div>A</div>}
                      >
                        <div
                          class='time-btn'
                          id='switch-time'
                          onClick={() => {
                            if (this.props.userSelectedTimeZone) {
                              this.OnChangeTimeZone(this.props.defaultTimeZone)
                              this.props.userChangeTimeZone(false)
                            } else {
                              this.timeZoneConvertor()
                              this.props.userChangeTimeZone(true)
                            }

                            this.setState({ animatorStoper: true })
                          }}
                        >
                          <i class='fas fa-exchange-alt'></i>
                        </div>
                      </OverlayTrigger>
                    ) : (
                      <div
                        class='time-btn'
                        id='switch-time'
                        onClick={() => {
                          if (this.props.userSelectedTimeZone) {
                            this.OnChangeTimeZone(this.props.defaultTimeZone)
                            this.props.userChangeTimeZone(false)
                          } else {
                            this.timeZoneConvertor()
                            this.props.userChangeTimeZone(true)
                          }

                          this.setState({ animatorStoper: true })
                        }}
                      >
                        <i class='fas fa-exchange-alt'></i>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* {this.props.event.is_jobfair == "1" ? null : this.props.event.homepageTopButtonEnabled == 0 ? null :<div class = "col-md-4 text-left text-md-right btn-download order-first order-lg-last mb-3 mb-lg-0">
                    <a style = {{cursor : 'pointer'}} onClick={this.props.donwloadAgendaButtonEvent} data-target ="#aganda_Modal" data-toggle = "modal" className="dwn-btn btn btn-primary rounded-0">
						<i class="fas fa-download"></i> Download Agenda 
					</a>
				</div>} */}

              {/* This is old design for Zone Selection */}

              {/* <div class = "col-sm-6">
                            <div class = "form-group">
                                <label class="control-label timezone_label mb-3 mb-md-0 float-left">
                                    <i class="fas fa-globe-americas"></i> <Trans>agendaTimeZone</Trans> 
                                </label>
                                    <select onChange = {this.OnChangeTimeZone} class = "form-control timezone_select rounded-0">
                                        <option value="-12:00" selected = {this.props.timeZone == "-12:00" ? true : false}>(GMT -12:00) Eniwetok, Kwajalein</option>
                                        <option value="-11:00" selected = {this.props.timeZone == "-11:00" ? true : false}>(GMT -11:00) Midway Island, Samoa</option>
                                        <option value="-10:00" selected = {this.props.timeZone == "-10:00" ? true : false}>(GMT -10:00) Hawaii</option>
                                        <option value="-09:50" selected = {this.props.timeZone == "-09:50" ? true : false}>(GMT -9:30) Taiohae</option>
                                        <option value="-09:00" selected = {this.props.timeZone == "-09:00" ? true : false}>(GMT -9:00) Alaska</option>
                                        <option value="-08:00" selected = {this.props.timeZone == "-08:00" ? true : false}>(GMT -8:00) Pacific Time (US &amp; Canada)</option>
                                        <option value="-07:00" selected = {this.props.timeZone == "-07:00" ? true : false}>(GMT -7:00) Mountain Time (US &amp; Canada)</option>
                                        <option value="-06:00" selected = {this.props.timeZone == "-06:00" ? true : false}>(GMT -6:00) Central Time (US &amp; Canada), Mexico City</option>
                                        <option value="-05:00" selected = {this.props.timeZone == "-05:00" ? true : false}>(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima</option>
                                        <option value="-04:50" selected = {this.props.timeZone == "-04:50" ? true : false}>(GMT -4:30) Caracas</option>
                                        <option value="-04:00" selected = {this.props.timeZone == "-04:00" ? true : false}>(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz</option>
                                        <option value="-03:50" selected = {this.props.timeZone == "-03:50" ? true : false}>(GMT -3:30) Newfoundland</option>
                                        <option value="-03:00" selected = {this.props.timeZone == "-03:00" ? true : false}>(GMT -3:00) Brazil, Buenos Aires, Georgetown</option>
                                        <option value="-02:00" selected = {this.props.timeZone == "-02:00" ? true : false}>(GMT -2:00) Mid-Atlantic</option>
                                        <option value="-01:00" selected = {this.props.timeZone == "-01:00" ? true : false}>(GMT -1:00) Azores, Cape Verde Islands</option>
                                        <option value="+00:00" selected = {this.props.timeZone == "+00:00" ? true : false}>(GMT) Western Europe Time, London, Lisbon, Casablanca</option>
                                        <option value="+01:00" selected = {this.props.timeZone == "+01:00" ? true : false}>(GMT +1:00) Brussels, Copenhagen, Madrid, Paris</option>
                                        <option value="+02:00" selected = {this.props.timeZone == "+02:00" ? true : false}>(GMT +2:00) Kaliningrad, South Africa</option>
                                        <option value="+03:00" selected = {this.props.timeZone == "+03:00" ? true : false}>(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg</option>
                                        <option value="+03:50" selected = {this.props.timeZone == "+03:50" ? true : false}>(GMT +3:30) Tehran</option>
                                        <option value="+04:00" selected = {this.props.timeZone == "+04:00" ? true : false}>(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi</option>
                                        <option value="+04:50" selected = {this.props.timeZone == "+04:50" ? true : false}>(GMT +4:30) Kabul</option>
                                        <option value="+05:00" selected = {this.props.timeZone == "+05:00" ? true : false}>(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent</option>
                                        <option value="+05:50" selected = {this.props.timeZone == "+05:50" ? true : false}>(GMT +5:30) Bombay, Calcutta, Madras, New Delhi</option>
                                        <option value="+05:75" selected = {this.props.timeZone == "+05:50" ? true : false}>(GMT +5:45) Kathmandu, Pokhara</option>
                                        <option value="+06:00" selected = {this.props.timeZone == "+06:00" ? true : false}>(GMT +6:00) Almaty, Dhaka, Colombo</option>
                                        <option value="+06:50" selected = {this.props.timeZone == "+06:50" ? true : false}>(GMT +6:30) Yangon, Mandalay</option>
                                        <option value="+07:00" selected = {this.props.timeZone == "+07:00" ? true : false}>(GMT +7:00) Bangkok, Hanoi, Jakarta</option>
                                        <option value="+08:00" selected = {this.props.timeZone == "+08:00" ? true : false}>(GMT +8:00) Beijing, Perth, Singapore, Hong Kong</option>
                                        <option value="+08:75" selected = {this.props.timeZone == "+08:75" ? true : false}>(GMT +8:45) Eucla</option>
                                        <option value="+09:00" selected = {this.props.timeZone == "+09:00" ? true : false}>(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk</option>
                                        <option value="+09:50" selected = {this.props.timeZone == "+09:50" ? true : false}>(GMT +9:30) Adelaide, Darwin</option>
                                        <option value="+10:00" selected = {this.props.timeZone == "+10:00" ? true : false}>(GMT +10:00) Eastern Australia, Guam, Vladivostok</option>
                                        <option value="+10:50" selected = {this.props.timeZone == "+10:50" ? true : false}>(GMT +10:30) Lord Howe Island</option>
                                        <option value="+11:00" selected = {this.props.timeZone == "+11:00" ? true : false}>(GMT +11:00) Magadan, Solomon Islands, New Caledonia</option>
                                        <option value="+11:50" selected = {this.props.timeZone == "+11:50" ? true : false}>(GMT +11:30) Norfolk Island</option>
                                        <option value="+12:00" selected = {this.props.timeZone == "+12:00" ? true : false}>(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka</option>
                                        <option value="+12:75" selected = {this.props.timeZone == "+12:75" ? true : false}>(GMT +12:45) Chatham Islands</option>
                                        <option value="+13:00" selected = {this.props.timeZone == "+13:00" ? true : false}>(GMT +13:00) Apia, Nukualofa</option>
                                        <option value="+14:00" selected = {this.props.timeZone == "+14:00" ? true : false}>(GMT +14:00) Line Islands, Tokelau</option>
                                    </select>
                                
                            </div>
                        </div> */}
              {/* <div class = "col-sm-6 text-center text-md-right">
                            <a style = {{cursor : 'pointer'}} onClick={this.props.donwloadAgendaButtonEvent} data-target ="#aganda_Modal" data-toggle = "modal" className="dwn-btn">
                                <Trans>downloadAgenda</Trans> <i className="ml-2 fas fa-download"></i>
                            </a>
                        </div> */}
            </div>
            {/* <h5 class = "text-center font-weight-bold text-uppercase">Have look into full Agenda</h5> */}
            <div class='row mt-3 mt-lg-5 hs__wrapper'>
              <div class='col-md-12'>
                {/* {!isMobile ?
                            <PerfectScrollbar style = {{height : "auto",minHeight : 0,maxHeight : 200}}>
                                <this.renderAgendaTabs
                                    agenda = {agenda} 
                                />
                            </PerfectScrollbar> : 
                             <div style = {{minHeight : 0,maxHeight : 200,overflowY : 'scroll'}}>
                                <this.renderAgendaTabs
                                    agenda = {agenda} 
                                />
                            </div>} */}
                <this.renderAgendaTabs agenda={agenda} />

                <div
                  class={`tab-content agenda-tab ${
                    this.props.userSelectedTimeZone ? 'time-upadate' : ''
                  }`}
                >
                  {agenda
                    ? agenda.map((agend, agendaIndex) => {
                        var h_id = 'agenda' + agendaIndex
                        var sessions = agend.sessions.data
                        var conClass =
                          agendaIndex == this.props.defaultAgendaIndex
                            ? 'container tab-pane active'
                            : 'container tab-pane fade'
                        return (
                          <div id={h_id} className={conClass} key={agendaIndex}>
                            <br />
                            {sessions.map((session, k) => {
                              var descriptionString =
                                session.description != null
                                  ? utility.extractContent(session.description)
                                  : ''
                              var hasMultiSession =
                                session.hasMultiSession == 1 ? true : false
                              var speakers = hasMultiSession
                                ? []
                                : session.speakers.data
                              var rowAdditionalClass = hasMultiSession
                                ? ' mx-0'
                                : ''
                              let liveStatusClass = ''
                              if (hasMultiSession) {
                                let isHappeningNow = false
                                let isOver = false
                                let count = 0
                                for (
                                  var i = 0;
                                  i < session.subSessions.length;
                                  i++
                                ) {
                                  if (session.subSessions[i].isHappeningNow) {
                                    isHappeningNow = true
                                    break
                                  }
                                  if (session.subSessions[i].isOver) {
                                    count += 1
                                    if (count == session.subSessions.length) {
                                      isOver = true
                                    }
                                  }
                                }
                                if (isHappeningNow) {
                                  liveStatusClass = 'live'
                                } else if (isOver) {
                                  liveStatusClass = 'past_event'
                                }
                              } else {
                                if (session.isHappeningNow) {
                                  liveStatusClass = 'live'
                                } else if (session.isOver) {
                                  liveStatusClass = 'past_event'
                                }
                              }
                              return (
                                <div
                                  id={agendaIndex + '' + k}
                                  className={
                                    'row tab-row bg-light border py-2 mb-3 ' +
                                    liveStatusClass
                                  }
                                  key={k}
                                >
                                  <div
                                    className={
                                      'col-sm-4 col-md-2 tab-col ' +
                                      (this.props.userSelectedTimeZone
                                        ? 'time-upadate'
                                        : '')
                                    }
                                  >
                                    <h5 className='text-center f-500 text-capitalize mb-0'>
                                      {/* {session.start_time} - {session.end_time} */}
                                      <span
                                        className={`${
                                          this.state.animatorStoper == false
                                            ? 'localtime'
                                            : 'event-time'
                                        }`}
                                        id='viewer2'
                                      >
                                        {session.start_time} -{' '}
                                        {session.end_time}
                                      </span>
                                      <span>
                                        {' '}
                                        {session.start_time} -{' '}
                                        {session.end_time}{' '}
                                      </span>
                                    </h5>
                                  </div>
                                  <div className='col-sm-8 col-md-10'>
                                    <div
                                      class={'row adj-row' + rowAdditionalClass}
                                    >
                                      {hasMultiSession ? (
                                        <>
                                          <div class='col-md-12 pl-0'>
                                            <p class='font-weight-bold text-center text-md-left'>
                                              {session.icon_type == 'icon' ? (
                                                <i
                                                  class={
                                                    session.session_icon +
                                                    ' mr-2'
                                                  }
                                                ></i>
                                              ) : session.icon_type ==
                                                'image' ? (
                                                <img
                                                  className='session-icon mr-2'
                                                  src={session.session_icon}
                                                />
                                              ) : null}
                                              {session.title}
                                            </p>
                                          </div>
                                          <div class='row spks_cols mx-0'>
                                            {session.subSessions.map(
                                              (item, subSessionIndex) => {
                                                let timeString = ''
                                                if (
                                                  item.start_time != null &&
                                                  item.end_time != null
                                                ) {
                                                  timeString =
                                                    '(' +
                                                    item.start_time +
                                                    ' - ' +
                                                    item.end_time +
                                                    ')'
                                                }
                                                let columnClass = ''
                                                if (
                                                  session.subSessions.length ==
                                                  4
                                                ) {
                                                  columnClass =
                                                    'col-lg-4 col-md-6 col-xl-3'
                                                } else if (
                                                  session.subSessions.length ==
                                                  3
                                                ) {
                                                  columnClass =
                                                    'col-lg-4 col-md-6'
                                                } else if (
                                                  session.subSessions.length ==
                                                  2
                                                ) {
                                                  columnClass = 'col-md-6'
                                                } else {
                                                  columnClass = 'col-12'
                                                }
                                                let watchButtonLeftMargin =
                                                  'ml-3'
                                                if (
                                                  this.state
                                                    .showStatusCheckLoader &&
                                                  this.state
                                                    .selectedSessionIndex ==
                                                    k &&
                                                  this.state
                                                    .selectedSubSessionIndex ==
                                                    subSessionIndex
                                                ) {
                                                  watchButtonLeftMargin = ''
                                                }
                                                return (
                                                  <div
                                                    class={
                                                      columnClass + ' mb-2'
                                                    }
                                                  >
                                                    <div class='stream-spk px-2 bg-white py-3 border h-100'>
                                                      <div class='col-md-12 px-2'>
                                                        <div className='container'>
                                                          <div className='row'>
                                                            {item.icon_type ==
                                                            'icon' ? (
                                                              <i
                                                                class={
                                                                  item.session_icon +
                                                                  ' mr-2'
                                                                }
                                                              ></i>
                                                            ) : item.icon_type ==
                                                              'image' ? (
                                                              <img
                                                                className='session-icon mr-2'
                                                                src={
                                                                  item.session_icon
                                                                }
                                                              />
                                                            ) : null}
                                                            <h6 class='font-weight-bold text-center text-md-left'>
                                                              {/* {item.title + " (" + item.session_duration + "  Min)"} */}
                                                              {item.title}
                                                            </h6>
                                                          </div>
                                                        </div>
                                                        <p class='stream-pra text-center text-md-left'>
                                                          {ReactHtmlParser(
                                                            item.description
                                                          )}
                                                          {!item.isOver ? (
                                                            <p class='bookmark-btn'>
                                                              {item.eventObj !=
                                                              null ? (
                                                                <AddToCalendar
                                                                  event={
                                                                    item.eventObj
                                                                  }
                                                                  listItems={
                                                                    items
                                                                  }
                                                                  buttonTemplate={
                                                                    icon
                                                                  }
                                                                />
                                                              ) : null}
                                                              {this.props
                                                                .user !=
                                                              null ? (
                                                                <span
                                                                  onClick={() =>
                                                                    this.OnClickFavorite(
                                                                      agendaIndex,
                                                                      k,
                                                                      subSessionIndex
                                                                    )
                                                                  }
                                                                  class={
                                                                    item.eventObj !=
                                                                    null
                                                                      ? 'star-btn ml-2'
                                                                      : 'star-btn'
                                                                  }
                                                                  data-toggle='tooltip'
                                                                  data-placement='top'
                                                                  title='Add to Favorite'
                                                                >
                                                                  <i
                                                                    id='bookmark-icons'
                                                                    class={
                                                                      item.is_favourite ==
                                                                      1
                                                                        ? 'fas fa-star'
                                                                        : 'far fa-star'
                                                                    }
                                                                  ></i>
                                                                </span>
                                                              ) : null}
                                                            </p>
                                                          ) : null}
                                                        </p>
                                                      </div>
                                                      <div class='col-12 px-2'>
                                                        {item.speakers.data.map(
                                                          (
                                                            speaker,
                                                            speakerIndex
                                                          ) => {
                                                            return (
                                                              <div class='row agenda_spk-list'>
                                                                <div
                                                                  class='s_name col-12 pl-5'
                                                                  data-toggle='modal'
                                                                  data-target='#bioModal'
                                                                >
                                                                  <div class='s_imgs'>
                                                                    {speaker.image !=
                                                                    null ? (
                                                                      <img
                                                                        src={
                                                                          speaker.image
                                                                        }
                                                                        class='rounded-circle adj_user'
                                                                        alt='user_1-img'
                                                                      />
                                                                    ) : (
                                                                      <img
                                                                        src='/images/profile-placeholder.png'
                                                                        class='rounded-circle adj_user'
                                                                        alt='user_1-img'
                                                                      />
                                                                    )}
                                                                  </div>
                                                                  <div
                                                                    onClick={() =>
                                                                      this.OnClickSeeBio(
                                                                        speaker
                                                                      )
                                                                    }
                                                                    class={
                                                                      speaker.description !=
                                                                      null
                                                                        ? 'underline ml-3'
                                                                        : 'default-cursor ml-3'
                                                                    }
                                                                  >
                                                                    {speaker.name +
                                                                      ' ' +
                                                                      (speaker.surname ==
                                                                      null
                                                                        ? ''
                                                                        : speaker.surname)}
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            )
                                                          }
                                                        )}
                                                      </div>
                                                      {item.virtual_access_status !=
                                                        0 &&
                                                      item.virtual_access_link !=
                                                        null ? (
                                                        <div
                                                          class={
                                                            this.state
                                                              .showStatusCheckLoader &&
                                                            this.state
                                                              .selectedSessionIndex ==
                                                              k &&
                                                            this.state
                                                              .selectedSubSessionIndex ==
                                                              subSessionIndex
                                                              ? 'row btns-row'
                                                              : 'ow mb-2 btns-row'
                                                          }
                                                        >
                                                          <div class='streaming-btns d-flex justify-content-center col-12'>
                                                            <a
                                                              onClick={() =>
                                                                this.OnClickVirtualAccessButton(
                                                                  item.virtual_access_status,
                                                                  agendaIndex,
                                                                  k,
                                                                  subSessionIndex
                                                                )
                                                              }
                                                              class={
                                                                'btn session_btn'
                                                              }
                                                            >
                                                              {item.virtual_access_status ==
                                                              1 ? (
                                                                item.isHappeningNow ? (
                                                                  <i class='fas fa-circle mr-3 tab-live' />
                                                                ) : (
                                                                  <i class='fas fa-broadcast-tower mr-2' />
                                                                )
                                                              ) : (
                                                                <i class='fas fa-play mr-2' />
                                                              )}
                                                              {item.virtual_access_status ==
                                                              1
                                                                ? 'Watch Live '
                                                                : 'Watch Recorded'}
                                                            </a>
                                                          </div>
                                                          {this.state
                                                            .showStatusCheckLoader &&
                                                          this.state
                                                            .selectedSessionIndex ==
                                                            k &&
                                                          this.state
                                                            .selectedSubSessionIndex ==
                                                            subSessionIndex ? (
                                                            <div className='text-center mb-1 w-100 loader'>
                                                              <Loader
                                                                type='Oval'
                                                                color='#00b6ff'
                                                                height={20}
                                                                width={20}
                                                              />
                                                            </div>
                                                          ) : null}
                                                        </div>
                                                      ) : null}
                                                    </div>
                                                  </div>
                                                )
                                              }
                                            )}
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div class='col-md-6'>
                                            <p class='font-weight-bold mb-0 text-center text-md-left agenda-title'>
                                              {session.icon_type == 'icon' ? (
                                                <i
                                                  class={
                                                    session.session_icon +
                                                    ' mr-2'
                                                  }
                                                ></i>
                                              ) : session.icon_type ==
                                                'image' ? (
                                                <img
                                                  className='session-icon mr-2'
                                                  src={session.session_icon}
                                                />
                                              ) : null}
                                              {session.title}
                                            </p>
                                            {session.hall != null ? (
                                              <p class='mb-0 text-center text-md-left'>
                                                {session.hall}
                                              </p>
                                            ) : null}
                                            {/* {this.state.collapseID == "collapsibleNavbar"+agendaIndex+k ? <p style = {{marginBottom : 0,width : speakers.length == 0 ? '50%' : '100%'}} class = "mt-3">{descriptionString.length > 150 ? ReactHtmlParser(session.description.substring(0,speakers.length > 0 ? 150 : 170)):ReactHtmlParser(session.description)}</p> : null} */}
                                            {this.state.collapseID !=
                                            'collapsibleNavbar' +
                                              agendaIndex +
                                              k ? (
                                              <>
                                                <p
                                                  style={{ marginBottom: 0 }}
                                                  class='mt-3'
                                                >
                                                  {descriptionString.length >
                                                  150
                                                    ? ReactHtmlParser(
                                                        session.description.substring(
                                                          0,
                                                          speakers.length > 0
                                                            ? 150
                                                            : 170
                                                        )
                                                      )
                                                    : ReactHtmlParser(
                                                        session.description
                                                      )}
                                                </p>
                                                {!session.isOver ? (
                                                  <p class='bookmark-btn'>
                                                    {session.eventObj !=
                                                    null ? (
                                                      <AddToCalendar
                                                        event={session.eventObj}
                                                        listItems={items}
                                                        buttonTemplate={icon}
                                                      />
                                                    ) : null}
                                                    {this.props.user != null ? (
                                                      <span
                                                        onClick={() =>
                                                          this.OnClickFavorite(
                                                            agendaIndex,
                                                            k
                                                          )
                                                        }
                                                        class={
                                                          session.eventObj !=
                                                          null
                                                            ? 'star-btn mt-1 ml-2'
                                                            : 'star-btn mt-1'
                                                        }
                                                        data-toggle='tooltip'
                                                        data-placement='top'
                                                        title='Add to Favorite'
                                                      >
                                                        <i
                                                          id='bookmark-icons'
                                                          class={
                                                            session.is_favourite ==
                                                            1
                                                              ? 'fas fa-star'
                                                              : 'far fa-star'
                                                          }
                                                        ></i>
                                                      </span>
                                                    ) : null}
                                                  </p>
                                                ) : null}
                                              </>
                                            ) : null}

                                            {/* {descriptionString.length > 150  ?
                                                                                <div class = "show_more">
                                                                                    <p class = "btn-tgl" onClick = {this.toggleDescriptionCollapse("collapsibleNavbar"+agendaIndex+k)}>
                                                                                        <i id={"collapsibleNavbar"+agendaIndex+k} class="fas fa-plus-square text-main"></i>
                                                                                    </p>
                                                                                </div> : null} */}
                                            {this.state.collapseID ==
                                            'collapsibleNavbar' +
                                              agendaIndex +
                                              k ? (
                                              <MDBCollapse
                                                style={{
                                                  marginTop:
                                                    session.description != null
                                                      ? '1rem'
                                                      : 0,
                                                  float: 'left'
                                                }}
                                                id={
                                                  'collapsibleNavbar' +
                                                  agendaIndex +
                                                  k
                                                }
                                                isOpen={
                                                  this.state.collapseID ==
                                                  'collapsibleNavbar' +
                                                    agendaIndex +
                                                    k
                                                }
                                              >
                                                <>
                                                  <p
                                                    style={{
                                                      marginTop: '1.25rem'
                                                    }}
                                                  >
                                                    {session.description != null
                                                      ? ReactHtmlParser(
                                                          session.description
                                                        )
                                                      : ''}
                                                  </p>
                                                  {!session.isOver ? (
                                                    <p class='bookmark-btn'>
                                                      {session.eventObj !=
                                                      null ? (
                                                        <AddToCalendar
                                                          event={
                                                            session.eventObj
                                                          }
                                                          listItems={items}
                                                          buttonTemplate={icon}
                                                        />
                                                      ) : null}
                                                      {this.props.user !=
                                                      null ? (
                                                        <span
                                                          onClick={() =>
                                                            this.OnClickFavorite(
                                                              agendaIndex,
                                                              k
                                                            )
                                                          }
                                                          class={
                                                            session.eventObj !=
                                                            null
                                                              ? 'star-btn ml-2'
                                                              : 'star-btn'
                                                          }
                                                          data-toggle='tooltip'
                                                          data-placement='top'
                                                          title='Add to Favorite'
                                                        >
                                                          <i
                                                            id='bookmark-icons'
                                                            class={
                                                              session.is_favourite ==
                                                              1
                                                                ? 'fas fa-star'
                                                                : 'far fa-star'
                                                            }
                                                          ></i>
                                                        </span>
                                                      ) : null}
                                                    </p>
                                                  ) : null}
                                                </>
                                              </MDBCollapse>
                                            ) : null}
                                          </div>
                                          <div
                                            // style = {{display : speakers.length == 0 ? 'none' : ''}}
                                            class={`col-md-6`}
                                            id='exp-col'
                                          >
                                            {speakers.length >
                                              this.minNoOfSpeakersToShow ||
                                            descriptionString.length >= 150 ? (
                                              <div class='show_more'>
                                                <p
                                                  class='btn-tgl'
                                                  onClick={this.toggleCollapse(
                                                    'collapsibleNavbar' +
                                                      agendaIndex +
                                                      k
                                                  )}
                                                >
                                                  <i
                                                    id={
                                                      'icon' + agendaIndex + k
                                                    }
                                                    class='fas fa-plus-square text-main'
                                                  ></i>
                                                </p>
                                              </div>
                                            ) : null}
                                            <div class='row justify-content-end'>
                                              {speakers
                                                .slice(
                                                  0,
                                                  this.minNoOfSpeakersToShow
                                                )
                                                .map((speaker, ss) => {
                                                  return (
                                                    <>
                                                      <div class='col-12 mb-2'>
                                                        <div class='row'>
                                                          <div class='col-3 col-md-2 pr-md-0 text-center'>
                                                            {speaker.image !=
                                                            null ? (
                                                              <img
                                                                src={
                                                                  speaker.image
                                                                }
                                                                class='rounded-circle shadow mt-2 adj_user'
                                                                alt='user_1-img'
                                                              />
                                                            ) : (
                                                              <img
                                                                src='/images/profile-placeholder.png'
                                                                class='rounded-circle shadow mt-2 adj_user'
                                                                alt='user_1-img'
                                                              />
                                                            )}
                                                          </div>
                                                          <div class='col-9 col-md-10 pl-0'>
                                                            <span
                                                              onClick={() =>
                                                                this.OnClickSeeBio(
                                                                  speaker
                                                                )
                                                              }
                                                              class={
                                                                speaker.description !=
                                                                null
                                                                  ? 'underline font-weight-bold mb-1 spkr-name'
                                                                  : 'default-cursor font-weight-bold mb-1 spkr-name'
                                                              }
                                                            >
                                                              {speaker.name +
                                                                ' ' +
                                                                (speaker.surname ==
                                                                null
                                                                  ? ''
                                                                  : speaker.surname)}
                                                            </span>
                                                            <p class='mb-0 mt-1'>
                                                              {
                                                                speaker.job_title
                                                              }
                                                            </p>
                                                            <p class='mt-1 f-500 mb-0'>
                                                              {speaker.company}
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </>
                                                  )
                                                })}
                                            </div>
                                            {speakers
                                              .slice(
                                                this.minNoOfSpeakersToShow,
                                                speakers.length
                                              )
                                              .map((speaker, ss) => {
                                                return (
                                                  <MDBCollapse
                                                    className='row'
                                                    id={
                                                      'collapsibleNavbar' +
                                                      agendaIndex +
                                                      k
                                                    }
                                                    isOpen={
                                                      this.state.collapseID ==
                                                      'collapsibleNavbar' +
                                                        agendaIndex +
                                                        k
                                                    }
                                                  >
                                                    {/* <div class = "collapse" id = {demoId}> */}
                                                    <div class='col-12 mb-2'>
                                                      <div class='row'>
                                                        <div class='col-2 pr-md-0'>
                                                          {speaker.image !=
                                                          null ? (
                                                            <img
                                                              src={
                                                                speaker.image
                                                              }
                                                              class='rounded-circle shadow mt-2 adj_user'
                                                              alt='user_1-img'
                                                            />
                                                          ) : (
                                                            <img
                                                              src='/images/profile-placeholder.png'
                                                              class='rounded-circle shadow mt-2 adj_user'
                                                              alt='user_1-img'
                                                            />
                                                          )}
                                                        </div>
                                                        <div class='col-10 pl-md-0'>
                                                          <span
                                                            onClick={() =>
                                                              this.OnClickSeeBio(
                                                                speaker
                                                              )
                                                            }
                                                            class={
                                                              speaker.description !=
                                                              null
                                                                ? 'underline font-weight-bold mb-1 spkr-name'
                                                                : 'default-cursor font-weight-bold mb-1 spkr-name'
                                                            }
                                                          >
                                                            {speaker.name +
                                                              ' ' +
                                                              (speaker.surname ==
                                                              null
                                                                ? ''
                                                                : speaker.surname)}
                                                          </span>
                                                          <p class='mb-0 mt-1'>
                                                            {speaker.job_title}
                                                          </p>
                                                          <p class='mt-1 f-500 mb-0'>
                                                            {speaker.company}
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </MDBCollapse>
                                                )
                                              })}
                                            {session.virtual_access_status !=
                                              0 &&
                                            session.virtual_access_link !=
                                              null ? (
                                              <this.renderWatchButton
                                                speakerLength={speakers.length}
                                                agendaIndex={agendaIndex}
                                                sessionIndex={k}
                                                virtualAccessStatus={
                                                  session.virtual_access_status
                                                }
                                                isHappeningNow={
                                                  session.isHappeningNow
                                                }
                                              />
                                            ) : null}
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        )
                      })
                    : null}
                </div>
              </div>
            </div>
            {/* {!Constants.showVirtualZone ? 
                    <div class = "row py-3">
                        <div class="col-md-12 text-center">
                            <span onClick = {this.props.onClickRegister} style = {{cursor : 'pointer'}} class = "btn text-uppercase bg_primary px-4 py-2">Book Now</span>
                        </div>
                    </div> : null} */}
          </div>
          <div class='modal' id='bioModalSchedule'>
            <div class='modal-dialog modal-lg modal-dialog-centered'>
              <div class='modal-content bio-modal'>
                <div class='modal-header px-3 px-md-5'>
                  <h3>
                    {selectedSpeaker.name +
                      ' ' +
                      (selectedSpeaker.surname == null
                        ? ''
                        : selectedSpeaker.surname)}
                  </h3>
                  <button data-dismiss='modal'>
                    <i class='fas fa-times-circle'></i>
                  </button>
                </div>
                {/* Modal body  */}
                <div class='modal-body px-3 px-md-5'>
                  {ReactHtmlParser(selectedSpeaker.description)}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  } //end of render
}
export default withRouter(Content)
